<div class="w-full relative z-20 py-5 bg-gradient-primary-to-black">
  <div class="flex flex-col lg:flex-row lg:py-20 py-10">
    <div
      class="flex flex-col lg:w-1/4 text-white justify-start items-start px-12"
      style="line-height: 2;"
    >
      <h1 class="text-ColorMain4" style="font-family: 'Almarai-Regular'">
        {{ 'about_us' | translate }}
      </h1>
      <span class="" style="font-family: 'Almarai-Regular';">
        {{ 'about_us_desc1' | translate }} <br>
        {{ 'about_us_desc2' | translate }}
      </span>
      <br>
    </div>

    <div
      class="flex flex-col lg:w-1/4 text-white justify-start items-start px-12"
    >
      <h1 class="text-ColorMain4 mt-5" style="font-family: 'Almarai-Regular'">
        {{ 'our_services' | translate }}
      </h1>
      <a class="" href="development">
        <img class="w-7 ml-5 mb-2" alt="" />
        <span class="" style="font-family: 'Almarai-Regular'">
          {{ 'software_development' | translate }}
        </span></a
      >

      <a class="" href="design">
        <img class="w-7 ml-5 mb-2" alt="" />
        <span class="" style="font-family: 'Almarai-Regular'">
          {{ 'graphic_design' | translate }}
        </span></a
      >

      <a class="" href="marketing">
        <img class="w-7 ml-5 mb-2" alt="" />
        <span class="" style="font-family: 'Almarai-Regular'">
          {{ 'digital_marketing' | translate }}
        </span></a
      >
      <br><br>
    </div>

    <div
      class="flex flex-col lg:w-1/4 text-white justify-start items-start px-12"
    >
      <h1 class="text-ColorMain4 sm:mt-5" style="font-family: 'Almarai-Regular'">
        {{ 'contact' | translate }}
      </h1>

      <div
        class="flex items-center hover:text-ColorMain1 cursor-pointer"
        (click)="goto2('tel:+966598080208')"
      >

        <fa-icon class="text-xl" [icon]="['fas', 'phone']"></fa-icon>
        <span
          class="text-center"
          style="font-family: 'Almarai-Regular'"
        >
          <span class="px-4 text-sm">0598080208</span>
        </span>
      </div>

      <div
        class="flex flex-row hover:text-ColorMain1 cursor-pointer"
        (click)="goto2('mailto:info@creativecloudsit.com')"
      >
        <fa-icon class="text-xl" [icon]="['far', 'envelope']"></fa-icon>
        <span
          class="text-center"
          style="font-family: 'Almarai-Regular'"
        >
          <span class="px-4 text-sm">info@creativecloudsit.com</span>
        </span>
      </div>

      <div (click)="goto2('https://maps.app.goo.gl/gzKUdWLu12YQxK2P6')" class="flex flex-row cursor-pointer hover:text-ColorMain1">
        <fa-icon
          class="text-xl cursor-pointer"
          [icon]="['fas', 'map-marker-alt']"
        ></fa-icon>
        <span
          class="text- px-5"
          style="font-family: 'Almarai-Regular'"
        >
          {{ 'address' | translate }}
        </span>
      </div>
      <br><br>
    </div>
    <div
      class="flex flex-col lg:w-1/4 text-white justify-start items-start px-12"
    >
      <h1 class="text-ColorMain4" style="font-family: 'Almarai-Regular'">
        {{ 'follow_us' | translate }}
      </h1>
      <div
        class="
          flex flex-row items-center
          hover:text-ColorMain1
          cursor-pointer
          transition-all
          duration-200
        "
        (click)="goto2('https://twitter.com/creativeclouds4?s=21')"
      >
        <!-- <fa-icon style="width: 20px;" class="text-xl ml-2" [icon]="['fab', 'twitter']"></fa-icon> -->
        <svg xmlns="http://www.w3.org/2000/svg" class="text-xl" fill="white" height="20" width="20" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
        <span style="font-family: 'Almarai-Regular'">
          <span class="px-2 text-sm">
            {{ 'twitter' | translate }}
          </span>
        </span>
      </div>
<!--
      <div
        class="
          flex flex-row
          hover:text-ColorMain1
          cursor-pointer
          transition-all
          duration-200
        "
        (click)="goto2('mailto:info@creativecloudsit.com')"
      >
        <fa-icon style="width: 20px;" class="text-xl" [icon]="['fab', 'facebook']"></fa-icon>
        <span style="font-family: 'Almarai-Regular'">
          <span class="px-2 text-sm">فيس بوك</span>
        </span>
      </div> -->

      <div
        class="
          flex flex-row items-center
          hover:text-ColorMain1
          cursor-pointer
          transition-all
          duration-200
        "
        (click)="
          goto2('https://instagram.com/creativecloudsit?utm_medium=copy_link')
        "
      >
        <fa-icon style="width: 20px;" class="text-xl" [icon]="['fab', 'instagram']"></fa-icon>
        <span  style="font-family: 'Almarai-Regular'">
          <span class="px-2 text-sm">
            {{ 'instagram' | translate }}
          </span>
        </span>
      </div>
    </div>
  </div>

  <div

    class="
      flex flex-row
      w-full
      justify-between
      items-center
      mb-5
      py-5
      lg:py-2
      px-3
      lg:px-5
    "
  >
    <fa-icon
      id="upBtn"
      style="left:14px; position: fixed;bottom: 50px; color:black; background: #0ae9fe; padding: 20px; border-radius: 50%; height: 30px; width: 30px; display: flex; justify-content: center; align-items: center;"
      class="invisible text-white hover:text-ColorMain4 text-xl cursor-pointer"
      [icon]="['fas', 'arrow-up']"
      (click)="upfun()"
    ></fa-icon>

    <!-- <img width="35px" src="..//assets/images/logo.png" alt="" /> -->
  </div>
  <div
    class="text-white text-center w-full"
    style="font-family: 'Almarai-Regular'"
  >
    @{{'creative_clouds' | translate}} . {{ 'all_rights_reserved' | translate}}
  </div>
</div>
