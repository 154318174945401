import { Component, OnInit, HostBinding } from '@angular/core';
import { User } from '../../user';
import * as AOS from 'aos';
import { ContactService } from '../..//contact.service';
import { TranslateService } from '@ngx-translate/core';

// import data from '../../../data/main';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    // animation triggers go here

    trigger('flyInout', [
      transition('void => *', [
        style({ transform: 'translatey(-300%)' }),
        animate('500ms 400ms'),
      ]),
    ]),

    trigger('shows', [
      transition('void => *', [
        style({ transform: 'translatex(-300%)' }),
        animate('3s'),
      ]),
    ]),

    trigger('showsmenu', [
      transition('void => *', [style({ width: '100vw' }), animate('3s')]),
    ]),
  ],
})

export class HomeComponent implements OnInit {

  title = 'CreativeCloudsIt';
  topics = [
    'تصميم وتطوير البرمجيات',
    'التسويق',
    'التحليل',
    'تجربة المستخدم',
    'إدارة وتشغيل التطبيقات',
  ];
  showDiv = true;
  topicHasError = true;
  submitting = false;
  submitted = false;

  faCoffee = faCoffee;

  public lottieConfig: Object;
  private anim: any;
  public animationSpeed: number = 1;

  public mybutton = document.getElementById('myBtn');

  private submissionForm: AngularFirestoreCollection<any> | null = null;

  userModel = new User('', '', null, '', '', '');


  card_data: any;
  value: any;

  constructor(
    private translate: TranslateService,
    private _contactService: ContactService,
    private firestore: AngularFirestore
  ) {
    this.lottieConfig = {
      path: 'assets/Files/ping.json',
      autoplay: true,
      loop: true,
    };
  }

  ngOnInit(): void {
    AOS.init();


    this.translate.get('data').subscribe((data:any)=> {
      // console.log(data),
      this.card_data = [
        {
          title: data.software_development,
          desc: data.software_development_desc,
          url: 'code',
          link: 'development',
        },
        {
          title: data.graphic_design,
          desc: data.graphic_design_desc,
          url: 'graphic-design',
          link: 'design',
        },
        {
          title: data.digital_marketing,
          desc: data.digital_marketing_desc,
          url: 'social-media',
          link: 'marketing',
        },
      ];
    });
  }

  validateTopic(value: string) {
    if (value === 'default') {
      this.topicHasError = true;
    } else {
      this.topicHasError = false;
    }
  }

  onSubmit() {
    this.submitted = true;
    this._contactService.contact(this.userModel).subscribe(
      (data) => console.log('sucess!', data),
      (error) => console.error('error!', error)
    );
  }

  submitData(value: any) {
    // fireBase part
    this.submitted = true;
    this.submissionForm
      ?.add(value)
      .then((res) => {
        this.submitted = true;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.submitting = false;
      });
  }

  goto(url: string) {
    window.location.href = url;
    console.log('click');
  }

  handleAnimation(anim: any) {
    console.log('before create ');

    this.anim = anim;

    console.log('after create ');
  }

  stop() {
    this.anim.stop();
  }

  play() {
    this.anim.play();
  }

  pause() {
    this.anim.pause();
  }
  setSpeed(speed: string) {
    const value = parseInt(speed);
    this.animationSpeed = value;
    this.anim.setSpeed(value);
  }

  // getTranslation(str: string) {
  //   this.translate.get(str).subscribe((data:any)=> { return data; });
  // }
}
