<script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
<div
  class="
    w-screen
    h-screen
    lg:h-screen
    flex flex-col
    justify-center
    pt-20
    lg:pt-20
    items-center
  "
  style="z-index: 3"
>
  <div class="flex flex-col lg:flex-row items-center lg:w-1/2 w-11/12">
    <div class="lg:w-full w-5/12">
      <ng-content></ng-content>
    </div>
    <div class="w-64 flex items-center flex-row lg:flex-col-reverse lg:w-full">
      <span
        class="
          text-3xl text-white
          font-Almarai-bold
          lg:text-6xl
          text-center
          z-10
          lg:mb-10
        "
      >
        {{ title }}
      </span>
    </div>
  </div>

  <div
    class="
      text-lg
      lg:px-0 lg:text-3xl lg:w-5/12
      w-7/12
      text-center text-gray-200
      z-10
      mt-10
      font-Almarai-Regular
    "
  >
    {{ desc }}
  </div>
  <div class="w-10 lg:w-10 mt-5">
    <lottie-player
      (click)="downfun()"
      class="cursor-pointer"
      src="https://assets1.lottiefiles.com/private_files/lf30_qlbsvfsd.json"
      background="transparent"
      speed="1"
      loop
      autoplay
    ></lottie-player>
  </div>
</div>
