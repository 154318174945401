import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.css'],
})
export class HeroSectionComponent implements OnInit {
  constructor(private translate: TranslateService) {}

  @Input() title = '' // this.translate.instant('hero_title');
  @Input() desc = '' //this.translate.instant('hero_description');;

  ngOnInit(): void {
    const AnimationDo = document.querySelector(`#hero-section-animation`);
    console.log('element ');
    console.log(AnimationDo);
    AnimationDo?.setAttribute(
      'src',
      'https://assets8.lottiefiles.com/packages/lf20_98gfq0gc.json'
    );
  }
  downfun() {
    window.scrollTo(100, 950);
  }
}
