<div
  class="
    flex flex-col
    lg:flex-row
    justify-around
    h-auto
    lg:h-504
    relative


    shadow
    {{ class }}
  "
>
  <div
    class="
      flex flex-col flex-1
      justify-center
      z-10
      py-20

      h-full
      shadow
      {{ classContent }}
    "
  >
    <span class="flex justify-center items-center">
      <fa-icon
        class="
          text-white
          hover:text-ColorMain1
          text-center text-8xl
          cursor-pointer
        "
        [icon]="icon"
      ></fa-icon
    ></span>
    <p
      class="text-xl lg:text-1xl text-center p-5 text-white z-10"
      style="font-family: Almarai-bold"
    >
      {{ title }}
    </p>
    <br />
    <p
      class="text-xl lg:text-1xl text-center px-5 text-white z-10"
      style="font-family: Almarai-regular"
    >
      {{ desc }}
    </p>
    <div class="flex justify-center items-center">
      <button
        class="
          bg-ColorMain1
          hover:bg-blue-700 hover:text-white
          text-black
          font-bold
          py-2
          px-4
          rounded-md
          w-36
        "
        (click)="contactDown()"
      >
        <p class="flex justify-center items-center font-Almarai-Regular">
          {{ 'get_quotation' | translate }}
        </p>
      </button>
    </div>
  </div>

  <div
    class="
      flex flex-col flex-1
      justify-center
      to-ColorMain3
      shadow
      rounded-lg
      max-h-64
      lg:max-h-full
      w-full
      lg:w-auto
    "
  >
    <img src="..//../../../assets/images/{{ image }}" class="h-full" />
  </div>

  <div></div>
</div>
