<navbar-pc class="{{ class }}"></navbar-pc>
<div
  class="flex items-center fixed w-full justify-between p-3"
  style="z-index: 100"
>
  <div
    class="
      mr-2
      lg:hidden
      flex
      items-center
      text-white text-opacity-70
      transition-all
      duration-200
      hover:text-opacity-100
      cursor-pointer
    "
  >
    <span><img class="w-12" src="..//assets/images/logo.png" alt="" /></span>
    <div class="font-Almarai-bold flex flex-col mr-4">
      <span class="">Creative Clouds IT</span>
      <span style="font-size: 0.7rem;">سُحُب الإبداع لتقنية المعلومات</span>
    </div>
  </div>

  <img class="lg:hidden" (click)="changeCurrentLangMobile('ar')" *ngIf="flagAr" style="width: 30px;" src="../assets/flags/ksa.png" alt="" />
  <img class="lg:hidden" (click)="changeCurrentLangMobile('en')" *ngIf="flagEn" style="width: 30px;" src="../assets/flags/usa.png" alt="" />

  <div
    class="lg:hidden text-white cursor-pointer bg-the"
    style="z-index: 100"
    (click)="closeSideMenu()"
  >

    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16" *ngIf="!showMenu">
      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
    </svg>

    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16" *ngIf="showMenu">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
    </svg>


    <!--
      <div id="nav-icon1">
      <span></span>
      <span></span>
      <span></span>
    </div>
    -->
  </div>



</div>
<div
  [style.width.vw]="logoWidth"
  class="
    h-screen
    z-50
    fixed
    top-0
    bottom-0
    flex flex-col
    items-center
    space-y-16
    pt-24
    transition-all
    overflow-hidden
    duration-300
  "
  style="
    background-color: #1b1818;
    font-family: Almarai-Regular;
    font-size: 32px;
  "
>
  <div class="flex flex-col w-full px-10 flex-1 overflow-hidden" style="font-size: 1rem;">
    <a class="text-ColorMain1 py-4" href="#home" (click)="closeSideMenu()">
      {{'home' | translate}}
    </a>
    <div class="w-full text-ColorMain1 relative">
      <div
        class="w-full flex items-center py-4"
        (click)="onOpenServices()"
      >
        <span>{{'services' | translate}} </span>
        &nbsp;

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16" class="transition-transform duration-200" [style.transform]="rotatedValue">
          <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </div>
      <!-- <div class="w-full" style="height: 200px"> -->
      <div
        [style.height]="heightService"
        class="
          bg-gray-900
          w-full
          flex
          flex-col
          overflow-hidden
          transition-all
          duration-200
          text-lg
        "
        [style.transform]="heightService"
      >
        <a
          class="text-ColorMain1 py-2 flex items-center gap-2" style="font-size:1rem;"
          href="development"
          (click)="closeSideMenu()"
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-terminal-fill" viewBox="0 0 16 16">
          <path d="M0 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm9.5 5.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1m-6.354-.354a.5.5 0 1 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2a.5.5 0 1 0-.708.708L4.793 6.5z"/>
        </svg>
          <!-- <img
            src="../../assets/images/code.svg"
            class="w-7 ml-5"
            alt=""
          /> -->
          {{'software_development' | translate}}</a
        >
        <a
          class="text-ColorMain1 py-2 flex items-center gap-2" style="font-size:1rem;"
          href="design"
          (click)="closeSideMenu()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-palette-fill" viewBox="0 0 16 16">
            <path d="M12.433 10.07C14.133 10.585 16 11.15 16 8a8 8 0 1 0-8 8c1.996 0 1.826-1.504 1.649-3.08-.124-1.101-.252-2.237.351-2.92.465-.527 1.42-.237 2.433.07M8 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m4.5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3M5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"/>
          </svg>

          <!-- <img
            src="../../assets/images/graphic-design.svg"
            class="w-7 mx-1"
            alt=""
          /> -->
          {{'graphic_design' | translate}}</a
        >
        <a
          class="text-ColorMain1 py-2 flex items-center gap-2" style="font-size:1rem;"
          href="marketing"
          (click)="closeSideMenu()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-megaphone-fill" viewBox="0 0 16 16">
            <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z"/>
          </svg>
          <!-- <img
            src="../../assets/images/social-media.svg"
            class="w-7 ml-5"
            alt=""
          /> -->
          {{'digital_marketing' | translate}}</a
        >
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
    <a class="text-ColorMain1 py-4" href="#about" (click)="closeSideMenu()">
      {{'about' | translate}}
    </a>

    <a class="text-ColorMain1 py-4" href="#contact" (click)="closeSideMenu()">
      {{'contact' | translate}}
    </a>

    <!-- <div class="dropdown">
      <button class="dropbtn hover:opacity-100 opacity-70">
        {{ 'language' | translate }}
        <fa-icon class="mr-4" [icon]="['fas', 'caret-down']"></fa-icon>
      </button>
      <div class="dropdown-content">
        <a (click)="changeCurrentLangMobile('en')">{{ 'en' | translate }}</a>
        <a (click)="changeCurrentLangMobile('ar')">{{ 'ar' | translate }}</a>
      </div>
    </div> -->

  </div>
  <div class="h-20"></div>

  <div
    (click)="openWhatsapp()"
    class="
      fixed
      bottom-12
      right-5
      bg-whatsapp
      shadow-xl
      rounded-full
      w-12
      h-12
      lg:w-auto lg:h-10
      z-30
      lg:px-5
      flex
      transition-all
      duration-200
      cursor-pointer
      justify-center
      items-center
      hover:bg-green-500
      text-white text-opacity-70
      hover:text-opacity-100
    "
  >
    <fa-icon
      [icon]="['fab', 'whatsapp']"
      class="text-2xl lg:text-3xl"
    ></fa-icon>
    <span class="mr-3 font-Almarai-Regular hidden lg:block text-sm"
      >{{'contact' | translate}}</span
    >
  </div>
</div>
