import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import data from '../../../data/design';
@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.css'],
})
export class DesignComponent implements OnInit {
  card1 = {
    desc: 'hello world',
  };
  card_data:any;
  constructor(
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {

    this.translate.get('data_design').subscribe((data:any)=> {
      this.card_data = [
        {
          title: data.graphic_design,
          desc: data.graphic_design_desc,
          url: 'graphic-design',
          link: 'design/#contact',
        },
        {
          title: data.identity_and_brand_design,
          desc: data.identity_and_brand_design_desc,
          url: 'logo-design',
          link: 'design/#contact',
        },
        {
          title: data.motion_graphics,
          desc: data.motion_graphics_desc,
          url: 'motion-graphic',
          link: 'design/#contact',
        },
        {
          title: data.three_dimensional_design,
          desc: data.three_dimensional_design_desc,
          url: '3d-model',
          link: 'design/#contact',
        },
        {
          title: data.ui_ux_desing,
          desc: data.ui_ux_desing_desc,
          url: 'ui-design',
          link: 'design/#contact',
        },
        {
          title: data.video_editing,
          desc: data.video_editing_desc,
          url: 'video',
          link: 'design/#contact',
        }
      ]
    });
  }

  upfun() {
    window.scrollTo(0, 0);
  }

  downfun() {
    window.scrollTo(100, 950);
  }

  goto(url: string) {
    window.location.href = url;
    console.log('click');
  }
}
