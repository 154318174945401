import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NotFound404Component } from './pages/not-found404/not-found404.component';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { MarketingComponent } from './pages/marketing/marketing.component';
import { DevelopmentServiceComponent } from './pages/development-service/development-service.component';
import { DesignComponent } from './pages/design/design.component';
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    // component:AppComponent,
    pathMatch: 'full',
  },

  {
    path: 'development',
    component: DevelopmentServiceComponent,
    // component:AppComponent,
    pathMatch: 'full',
  },

  {
    path: 'design',
    component: DesignComponent,
    // component:AppComponent,
    pathMatch: 'full',
  },

  {
    path: 'app2',
    component: AppComponent,
    // component:AppComponent,
    pathMatch: 'full',
  },
  { path: '404', pathMatch: 'full', component: NotFound404Component },
  { path: 'marketing', pathMatch: 'full', component: MarketingComponent },

  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    CommonModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
