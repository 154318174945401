import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// import third-party module
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';

import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { HomeComponent } from './home/home.component';
// import { AppIconsComponent } from './app-icons/app-icons.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { LottieAnimationViewModule } from 'ng-lottie';
import { FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { AngularFireModule, ɵAngularFireSchedulers } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';

// import {MatGridListModule} from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
// import {MatButtonModule} from '@angular/material/button';
// import {MatInputModule} from '@angular/material/input';
// import {MatCardModule} from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
// import {MatTabsModule} from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// import {MatSliderModule} from '@angular/material/slider';

// import {MatMenuModule} from '@angular/material/menu';
// import {MatListModule} from '@angular/material/list';

// import {MatCheckboxModule} from '@angular/material/checkbox';

// import {MatAutocompleteModule} from '@angular/material/autocomplete';
// import { AngularFireModule, ɵAngularFireSchedulers } from '@angular/fire';
// import { AngularFirestore } from '@angular/fire/firestore';
import { NgAuthService } from './ng-auth.service';
import { NotFound404Component } from './pages/not-found404/not-found404.component';
import { SplashScreenComponent } from './pages/splash-screen/splash-screen.component';
import { HomeComponent } from './pages/home/home.component';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { DevelopmentServiceComponent } from './pages/development-service/development-service.component';

import { DesignComponent } from './pages/design/design.component';

import { NavbarComponent } from './components/template/navbar/navbar.component';
import { ContactComponent } from './components/template/contact/contact.component';
import { FooterComponent } from './components/template/footer/footer.component';
import { MarketingComponent } from './pages/marketing/marketing.component';
import { SectionCardComponent } from './components/molecules/list-cards/list-cards.component';
import { CardSectionComponent } from './components/atom/card-section/card-section.component';
import { CardWithImageComponent } from './components/atom/card-with-image/card-with-image.component';
import { HeroSectionComponent } from './components/atom/hero-section/hero-section.component';
import { NavbarPcComponent } from './components/atom/navbar-pc/navbar-pc.component';
import { FeatureBoxComponent } from './components/atom/feature-box/feature-box.component';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

@NgModule({
  declarations: [
    AppComponent,
    NotFound404Component,
    HomeComponent,
    SplashScreenComponent,
    DevelopmentServiceComponent,
    DesignComponent,
    NavbarComponent,
    ContactComponent,
    FooterComponent,
    MarketingComponent,
    SectionCardComponent,
    CardSectionComponent,
    CardWithImageComponent,
    HeroSectionComponent,
    NavbarPcComponent,
    FeatureBoxComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatFormFieldModule,
    // MatCheckboxModule,
    // MatAutocompleteModule,
    MatChipsModule,
    MatDividerModule,
    MatToolbarModule,
    // MatListModule,
    MatSelectModule,
    FormsModule,
    MatMenuModule,
    HttpClientModule,
    MatProgressBarModule,
    LottieAnimationViewModule.forRoot(),

    AngularFireModule.initializeApp({
      apiKey: "AIzaSyBi7TcT6-YE-FIJkE0rw-VXtWS9w3Xp6qw",
      authDomain: "oursite-1716d.firebaseapp.com",
      databaseURL: "https://oursite-1716d-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "oursite-1716d",
      storageBucket: "oursite-1716d.appspot.com",
      messagingSenderId: "533259416231",
      appId: "1:533259416231:web:1c5c1a337e4a03635fe182",
      measurementId: "G-SF1GQWXLD7"
    }),

    AnimateOnScrollModule.forRoot(),
    FontAwesomeModule,

    AngularFireAnalyticsModule,


    TranslateModule.forRoot(),
    HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            defaultLanguage: 'en'

        })
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
