<div id="contact" class="flex justify-center items-center lg:h-screen">

  <div class="flex flex-col justify-center items-center w-full z-10 py-5 px-10 h-3/4">

    <div class="flex justify-center z-10 pb-5">
      <p class="text-2xl lg:text-3xl font-Almarai-bold text-center text-white px-4  z-50" style="font-family: Almarai-bold">
        {{ 'contact_us_to_get_an_offer' | translate }}
      </p>
      <br />
    </div>


    <div
      *ngIf="!submitted; else showSubmitted"
      class="flex flex-col lg:flex-row relative justify-items-stretch w-95/100 lg:w-3/5 lg:h-auto bg-texture-4 bg-cover rounded-xl shadow-xl overflow-hidden"
    >
      <div class="flex flex-1 flex-col relative">
        <div
          class="px-10 flex-1 flex justify-center flex-col lg:h-auto bg-black bg-opacity-60 text-white top-0 right-0 bottom-0 left-0 lg:left-0 font-Almarai-Regular w-full text-3xl lg:text-6xl py-10 lg:py-0"
          style="z-index: 0"
        >
          <span
            class="pt-5 lg:pt-0 text-center text-ColorMain4 lg:text-right mb-0 lg:mb-10"
          >
            {{ 'we_are_happy' | translate }}
          </span>
          <span class="py-10 lg:py-0 text-center lg:text-right lg:leading-10"
            ><span class="text-white-shadow-animation"> {{'to_contact' | translate}} </span>
            <span class="text-ColorMain4"> {{'with_you' | translate}} </span></span
          >

          <img
            src="../../../../assets/images/logo-text.png"
            class="w-20 absolute left-5 bottom-5"
            alt=""
          />
        </div>
      </div>
      <!-- <div class="flex-1 w-full h-96"></div> -->
      <div
        *ngIf="!submitted; else showSubmitted"
        (ngSubmit)="onSubmit()"
        id="contact"
        class="justify-center items-center w-full px-10 h-full lg:w-4/12 shadow flex-1 z-10 bg-theme-primary"
        style="
          background: #fff;
          /* background: linear-gradient(
      180deg,
      rgb(253, 15, 233) 0%,
      rgb(153, 43, 135) 44%,
      rgb(253, 15, 233) 100%
    ); */
        "
      >
        <!-- (ngSubmit)="onSubmit()" -->

        <form
          class="p-10 h-full text-4xl"
          #userForm="ngForm"
          action=""
          (ngSubmit)="submitData(userForm.form.value)"
          novalidate
        >
          <fieldset>
            <label for="uname">{{ 'full_name' | translate}}</label>
            <input
              [class.is-invalid]="name.invalid"
              class=""
              #name="ngModel"
              [placeholder]="'enter_full_name' | translate"
              type="text"
              required
              name="uname"
              [(ngModel)]="userModel.name"
            />
            <small
              [class.hidden]="name.valid || name.untouched"
              class="flex text-sm justify-start text-left text-danger"
              > {{'name_required' | translate}} </small
            >
          </fieldset>
          <div class="grid lg:grid-cols-2 gap-4">
            <fieldset class="flex">

              <label for="email">{{'your_email' | translate}}</label>
              <input
                #email="ngModel"
                placeholder="email@address.com"
                type="email"
                required
                name="email"
                [(ngModel)]="userModel.email"
              />
              <small
                [ngClass]="{ 'opacity-0': email.valid || email.untouched }"
                class="flex text-sm justify-start text-left text-danger"
              >
                {{'email_address_required' | translate}} </small
              >

            </fieldset>

            <fieldset class="flex">
              <label for="phoneNumber">{{'your_mobile_number' | translate}}</label>
              <input
              class="w-full rounded text-sm"
              #phone="ngModel"
                pattern="^\d{10}$"
                [class.is-invalid]="
                  phone.errors && (phone.invalid || phone.touched)
                "
                placeholder="********* +966 "
                type="number"
                required
                name="phoneNumber"
                [(ngModel)]="userModel.phone"
              />

              <!-- <small
  [class.hidden]="phone.valid || phone.untouched"
  class="text-sm justify-start text-right"
  >رقم الجوال يجب أن يحتوي على 10 ارقام
</small> -->
              <div
                *ngIf="phone.errors && (phone.invalid || phone.touched)"
                [ngClass]="{
                  'opacity-0': phone.errors && (phone.invalid || phone.touched)
                }"
              >
                <small
                  class="flex text-sm justify-start text-left text-danger"
                  *ngIf="phone.errors.required"
                  >{{'phone_number_required'}}</small
                >
                <small
                  class="flex text-sm justify-start text-left text-danger"
                  *ngIf="phone.errors.pattern"
                >
                  {{'phone_number_must_be_10_digits' | translate}}
                </small>
              </div>
            </fieldset>
          </div>

          <fieldset class="felx justify-start items-start">
            <label for="service">{{'select_service' | translate}}</label>
            <select
              #topic="ngModel"
              [(ngModel)]="userModel.topic"
              name="topic"
              name="service"
              [class.is-invalid]="topic.invalid && phone.touched"
              class="flex justify-start items-start"
            >
              <option class="font-Almarai-Regular" selected value="">
                {{'choose_service' | translate}}
              </option>
              <option
                class="font-Almarai-Regular"
                *ngFor="let topic of SelectionOption"
              >
                <div class="font-Almarai-Regular">{{ topic }}</div>
              </option>
            </select>
            <small
              class="flex text-sm justify-start text-left text-danger"
              *ngIf="topic?.errors?.required"
            >
              {{'you_must_choose_service' | translate}}
              </small
            >
          </fieldset>
          <fieldset class="flex-1">
            <label for="message"> {{'write_description' | translate}} </label>
            <textarea
              #message="ngModel"
              [placeholder]="'write_message_here' | translate"
              required
              minlength="10"
              required
              [(ngModel)]="userModel.message"
              name="message"
              class="focus:ring-2 focus:ring-blue-600 ..."
            ></textarea>
            <small
              [class.hidden]="message.valid || message.untouched"
              class="flex text-sm justify-start text-left text-danger"
              >
              {{'please_write_your_message_to_communicate' | translate}}
            </small>
          </fieldset>
          <fieldset>
            <label for="weburl"> {{'website' | translate}} </label>
            <input
              #weburl="ngModel"
              [class.is-invalid]="weburl.invalid && weburl.touched"
              placeholder="www.websitename.com"
              type="url"
              name="weburl"
              [(ngModel)]="userModel.weburl"
            />
          </fieldset>
          <fieldset>
            <div class="" *ngIf="submitting">
              <mat-progress-bar mode="query"></mat-progress-bar>
            </div>

            <button
              [disabled]="userForm.form.invalid"
              name="submit"
              type="submit"
              id="contact-submit"
              data-submit="...Sending"
              (ngSubmit)="submitData(userForm.form.value)"
            >
              {{'send' | translate}}
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  </div>

  <ng-template class="" #showSubmitted>
    <div
      class="text-center lg:px-0 h-screen flex flex-col justify-center items-center"
    >
      <div class="w-screen flex justify-center pt-64">
        <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
        <lottie-player
          class="lg:ml-60 ml-40 w-full scale-150 lg:w-504 h-4 lg:h-504"
          src="https://assets10.lottiefiles.com/packages/lf20_Cc8Bpg.json"
          background="transparent"
          speed="1"
          style="height: 200px"
          loop
          autoplay
        ></lottie-player>
      </div>
      <div class="w-screen px-10 lg:px-0 pb-64">
        <h1
          style="font-family: Almarai-Regular"
          class="text-xl text-white w-full"
        >
        {{'thank_you_for_contacting_us' | translate}}
        </h1>
      </div>
    </div>
  </ng-template>
</div>
<!--
<script src="https://smtpjs.com/v3/smtp.js"></script>
-->
