<div
  class="app-splash-screen"
  [ngStyle]="{ left: windowWidth }"
  *ngIf="showSplash"
>
  <div class="app-splash-inner">
    <div class="app-logo"></div>
    <div class="app-label" style="font-family: Almarai-Regular">
      أهلا بالعالم
    </div>
    <div class="app-loader"></div>
  </div>
</div>
