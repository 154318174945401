import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User} from './user'


@Injectable({
  providedIn: 'root'
})
export class ContactService {

  _url = 'http://localhost:3000/contact';


  constructor(private _http: HttpClient,) { }


  contact(user: User ){
    


    return  this._http.post<any>(this._url, user);
 
   }


}
