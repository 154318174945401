import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as AOS from 'aos';
@Component({
  selector: 'list-cards',
  templateUrl: './list-cards.component.html',
  styleUrls: ['./list-cards.component.css'],
})
export class SectionCardComponent implements OnInit {
  @Input() data: { title: string; desc: string; url: string; link?: string }[] =
    [];
  @Input() class: string = '';
  @Input() button_title: string = 'عرض السعر';
  @Input() link: string = '#contact';
  constructor() {}

  ngOnInit(): void {
    AOS.init();
  }
}
