<div class="">
  <navbar class="lg:bg-theme-primary"></navbar>

  <div
    class="
      flex flex-col
      lg:flex-row
      bg-black
      justify-center
      items-center
      lg:w-screen
      h-screen
      overflow-hidden
      fixed
      top-0
      right-0
      left-0
      bottom-0
    "
    style="
      background: rgb(193, 112, 153);
      background: linear-gradient(
        to top right,
        #716bde 0%,
        #716bde 50%,
        rgb(99, 35, 248) 99%
      );
    "
  >
    <div class="" style="z-index: 2">
      <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
      <lottie-player
        id="hero-section-animation"
        src="https://assets8.lottiefiles.com/packages/lf20_m2emnrvx.json"
        background="transparent"
        speed="0.5"
        style="width: 100vh; height: 90vh; transform: scale(4)"
        autoplay
      ></lottie-player>
    </div>
  </div>
  <hero-section
    title="{{'graphics_design_services' | translate}}"
    desc="{{'graphics_design_description' | translate}}"
  >
    <lottie-player
      src="https://assets8.lottiefiles.com/packages/lf20_w0jj5msg.json"
      background="transparent"
      speed="1"
      style="width: 100%; height: 100%"
      loop
      autoplay
    ></lottie-player>
  </hero-section>

  <div class="flex flex-col mt-24 h-full justify-around w-screen">
    <!-- <div class="flex justify-center pb-40">
      <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
      <lottie-player
        src="https://assets8.lottiefiles.com/packages/lf20_g8qlgjct.json"
        background="transparent"
        speed="1"
        style="width: 400px; height: 400px"
        loop
        autoplay
      ></lottie-player>
    </div> -->

    <div class="w-screen flex flex-col justify-center items-center">
      <span class="text-center font-Almarai-bold text-4xl text-white mb-5"
        >
          {{ "our_services" | translate }}
        </span
      >
      <list-cards
        [data]="card_data"
        class="bg-texture-7"
        button_title="{{'get_quotation' | translate}}"
      ></list-cards>
    </div>
    <div
      class="
        text-2xl
        lg:text-3xl
        font-Almarai-bold
        text-center text-white
        px-4
        mt-16
        space-y-2
        z-10
      "
    >
      {{ "what_do_you_benefit_form" | translate }}
    </div>
    <div
      class="
        w-screen
        flex
        h-auto
        lg:h-96
        justify-center
        items-center
        z-20
        mt-10
      "
    >
      <div
        class="
          w-full
          lg:w-1/2
          h-auto
          lg:h-96
          flex
          items-center
          justify-around
          flex-col
          lg:flex-row
        "
      >
        <feature-box
          src="target-audience"
          title="{{'reaching_larger_segments_of_the_audience' | translate}}"
        ></feature-box>
        <feature-box
          src="quality"
          title="{{'high_quality_and_mastering_design_for_your_printed_and_digital_products' | translate}}"
        ></feature-box>
        <feature-box
          src="interactive"
          title="{{'attract_your_customers_with_innovative_marketing_campaigns' | translate}}"
        ></feature-box>
        <!-- <div feature-box title="الوصول لشرائح أكبر من الجمهور"></div>
        <div
          feature-box
          title="تصميم عالي الجودة والإتقان لمنتجاتك المطبوعة والرقمية"
        ></div>
        <div feature-box title="جذب عملائك بحملات تسويقية مبتكرة"></div> -->
      </div>
    </div>
    <div
      class="
        text-2xl
        lg:text-3xl
        font-Almarai-bold
        text-center text-white
        px-4
        mt-20
        space-y-2
        z-10
      "
    >
      {{ "why_you_should_choose_us" | translate }}
    </div>
    <div
      class="
        w-screen
        flex
        h-auto
        lg:h-96
        justify-center
        items-center
        z-20
        mt-10
      "
    >
      <div
        class="
          w-full
          lg:w-1/2
          h-auto
          lg:h-96
          flex
          items-center
          justify-around
          flex-col
          lg:flex-row
        "
      >
        <feature-box
          [outline]="true"
          src="profit"
          title="{{'increase_your_customers_and_revenue' | translate}}"
        ></feature-box>
        <feature-box
          [outline]="true"
          src="brain"
          title="{{'establishing_your_brand_with_your_audience' | translate}}"
        ></feature-box>
        <feature-box
          [outline]="true"
          src="target"
          title="{{'focus_on_foundation' | translate}}"
        ></feature-box>

        <!-- <div feature-box title="الوصول لشرائح أكبر من الجمهور"></div>
        <div
          feature-box
          title="تصميم عالي الجودة والإتقان لمنتجاتك المطبوعة والرقمية"
        ></div>
        <div feature-box title="جذب عملائك بحملات تسويقية مبتكرة"></div> -->
      </div>
    </div>
    <div class="flex flex-col h-full justify-around w-screen">
      <div class="flex justify-center z-10">
        <p
          class="text-2xl pt-10 w-504 text-center text-white z-10"
          style="font-family: Almarai-bold"
        >
          <!-- تواصل معنا لطلب الخدمة -->
        </p>
        <br />
      </div>

      <contact typeService="DESIGN"></contact>

      <footer id="contact-design" class="bg-theme-primary"></footer>
    </div>
  </div>
</div>
