<navbar class="lg:bg-theme-primary"></navbar>
<div
  class="
    fixed
    bg-gradient-to-tr
    from-theme-third
    to-theme-primary
    top-0
    left-0
    right-0
    bottom-0
    z-0
  "
  style="z-index: 1"
></div>
<div class="relative z-10 w-full text-white font-Almarai-Regular">
  <hero-section
    link="https://assets8.lottiefiles.com/packages/lf20_98gfq0gc.json"
    title="{{ 'digital_marketing' | translate }}"
    desc="{{ 'digital_marketing_description' | translate }}"
  >
    <lottie-player
      id="hero-section-animation"
      src="https://assets8.lottiefiles.com/packages/lf20_98gfq0gc.json"
      background="transparent"
      style="width: 100%; height: 100%"
      speed="1"
      loop
      autoplay
    ></lottie-player>
  </hero-section>
  <div class="w-screen flex flex-col justify-center items-center">
    <list-cards [data]="card_data" class="bg-texture-6"></list-cards>
  </div>
  <contact typeService="MARKETING"> </contact>
  <footer></footer>
</div>
