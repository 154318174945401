<script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>
<link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet" />

<script>
  AOS.init();
</script>

<div style="overflow-x: hidden">
  <navbar class="bg-opacity-40 bg- bg-theme-secondary"></navbar>
  <div
    class="
      bg-texture-4 bg-cover bg-center bg-no-repeat
      absolute
      top-0
      right-0
      h-screen
      left-0
      bottom-0
      z-0
    "
    style="height: 100vh; z-index: 1"
  >
    <div
      class="top-0 right-0 left-0 bottom-0 bg-black absolute opacity-60"
      style="z-index: 0"
    ></div>
  </div>
  <div class="flex flex-col">
    <div
      class="
        flex flex-col-reverse
        lg:flex-row-reverse
        items-center
        h-screen
        lg:h-screen
        w-screen
        relative
        justify-center
        lg:justify-around lg:px-52
      "
      style="height: 100vh; z-index: 2;"
    >
      <!-- new -->
      <!-- <div></div> -->
      <div
        id="home"
        class="
          flex flex-col-reverse
          lg:flex-row lg:justify-between
          items-center
        "
      >
        <div class="lg:pt-48">
          <div>
            <span
              class="
                text-white text-3xl
                lg:text-7xl
                flex flex-col
                text-center
                w-full
                lg:w-96
              "
              style="font-family: Almarai-Regular"
              style="font-family: Almarai-Regular"
            >
              <span class="text-white-shadow"> {{'towards' | translate}} </span>
              <div
                style="direction: ltr;"
                class="
                  flex
                  items-center
                  justify-center
                  lg:justify-around
                  my-2
                  lg:my-5
                "
              >
                <fa-icon
                  [icon]="['fas', 'chevron-left']"
                  class="text-ColorMain4 text-blue-shadow text-xl lg:text-4xl"
                ></fa-icon>
                <span class="text-ColorMain4 text-4xl font-bold">/</span>
                <div class="text-ColorMain1 px-3 lg:px-0">{{'softwares' | translate}}</div>

                <fa-icon
                  [icon]="['fas', 'chevron-right']"
                  class="text-ColorMain4 text-pink-shadow text-xl lg:text-4xl"
                ></fa-icon>
              </div>
              <span class="text-white-shadow-animation"> {{'forever' | translate}} </span>
            </span>
          </div>

          <div class="flex w-screen justify-center lg:w-96 pt-10 lg:pt-20">
            <button
              class="
                bg-gradient-brand
                animation-btn-brand
                text-white
                rounded-full
                p-1
              "
              type="button"
              style="font-family: Almarai-Regular"
            >
              <div
                class="
                  w-full
                  h-full
                  bg-ColorMain3
                  text-gray-200
                  rounded-full
                  text-sm
                  lg:text-lg
                  px-4
                  py-1
                  transition-all
                  duration-200
                  hover:text-white hover:bg-ColorMain2
                "
              >
                <a href="#start">{{'lets_role' | translate }}</a>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <span
          class="
            text-white text-white-shadow text-4xl
            lg:text-8xl
            font-Almarai-Regular
            mb-10
          "
          >{{'company_name' | translate}}</span
        >
        <span
          class="
            text-gray-300
            opacity-60
            text-right text-6xl
            font-Almarai-Regular
            text-pink-shadow-animation
            hidden
            lg:block
          "
          >{{'information_technology' | translate}}</span
        >
      </div>
      <!-- <div class="mt-0 z-10">
        <lottie-player
          class="h-64 lg:h-504 lg:w-504"
          src="https://assets5.lottiefiles.com/private_files/lf30_SEyHT8.json"
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player>
      </div> -->
    </div>
    <div
      id="start"
      class="
        flex flex-col
        lg:flex-row
        justify-around
        bg-gradient-to-b
        from-black
        via-theme-secondary
        to-theme-black
        items-center
        w-screen
        py-0
        lg:py-52
      "
    >
      <div class="mt-0 z-10">
        <!-- <lottie-player
          class="h-64 lg:h-504 lg:w-504"
          src="https://assets3.lottiefiles.com/private_files/lf30_4g0jvti7.json"
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player> -->
        <img
          src="..//assets/images/laptop-2.png"
          alt="creative-clouds-it"
          style="width: 120%; transform: scale(1)"
        />
      </div>
      <!-- <img
          src="..//assets/images/laptop-2.png"
          alt="creative-clouds-it"
          style="width: 120%; transform: scale(0.6)"
        /> -->

      <div class="mt-10 md:mx-10 px-10">
        <span
          class="
            text-white text-4xl
            lg:text-7xl
            flex flex-col
            items-center
            lg:items-start lg:text-right
            text-right
          "
          style="font-family: Almarai-Regular"
        >
          {{'merge' | translate}}
          <span
            class="
              text-theme-primary
              bg-white
              w-auto
              p-4
              my-10
              rounded
              lg:mr-0
            "
          >
            {{'science' | translate}}
          </span>
          {{'art' | translate}}
        </span>
        <div class="flex justify-center pt-10 lg:w-504">
          <span
            class="text-gray-200 text-2xl w-full text-center lg:text-left"
            style="font-family: Almarai-Regular; line-height: 1.8;"
          >
            {{'home_description_one' | translate}}
          </span>
        </div>
      </div>

      <!-- <div class="lg:pt-56 lg:pb-56 lg:mx-0 mx-10 my-5 lg:my-0">
        <img
          data-aos="fade-up-right"
          data-aos-delay="600"
          data-aos-offset="0"
          data-aos-duration="2000"
          class="rounded-xl shadow"
          src="..//assets/images/detials2.png"
          alt=""
        />
      </div> -->
    </div>
    <div
      id="about"
      class="
        flex flex-col
        lg:flex-row
        justify-around
        items-center
        z-10
        bg-gradient-primary-to-black
        shadow
        lg:px-36
        pt-10
        h-96
      "
      style="height: 100vh"
    >
      <div class="flex h-auto items-center">
        <div
          class="
            flex flex-col flex-1
            justify-center
            mt-10
            to-ColorMain3
            rounded-lg
          "
        >
          <div class="flex items-center justify-center w-full relative">
            <!-- <div class="h-2 flex-1 lg:flex-none bg-white"></div> -->
            <!-- <div
              class="bg-gradient-brand h-2 w-full flex-1 absolute rounded-full"
              style="z-index: -1"
            ></div> -->
            <div
              class="
                text-3xl
                lg:text-5xl
                font-Almarai-bold
                text-center
                lg:w-full lg:mx-0 lg:text-left
                text-white
                logo_text
                px-1
                lg:px-0
                mb-5
              "
            >
              {{'why_creative_clouds' | translate}}
            </div>
            <!-- <div class="bg-white h-2 flex-1 lg:flex-none"></div> -->
          </div>

          <br />
          <span
            class="
              text-gray-300 text-xl
              lg:text-2xl
              text-center
              lg:text-left
              px-5
              lg:px-0
              pb-10
            "
            style="font-family: Almarai-Regular"
          >
            {{'work_in_a_way_that_continuous' | translate}}
            <br />
            {{'and_flow_in_various_projects' | translate}}

            <br />
            {{'the_focus_is_on_achieving_outputs_that_cover_all_dimensions_of_the_project' | translate}}
            <br />
            {{'it_ensures_continuity_and_expansion' | translate}}
            <br />
            {{'down_to_ease_of_use_and_beauty_of_the_product' | translate}}
          </span>
        </div>
        <div
          class="h-72 w-2 rounded-full ml-10 bg-gradient-brand hidden lg:block"
        ></div>
      </div>

      <div
        class="
          flex flex-col flex-1
          justify-center
          items-center
          z-10
          to-ColorMain3
          shadow
          rounded-lg
          w-4/6
          h-full
          relative
        "
      >
        <div class="relative flex justify-center items-center">
          <img
            src="..//../assets/images/logo-text.png"
            class="w-96 logo_shine animate-bounce"
            alt="creative clouds it"
            style="width: 28rem"
          />
        </div>
      </div>
    </div>
    <div
      id="service"
      class="w-screen flex flex-col justify-center items-center"
    >
      <list-cards
        [data]="card_data"
        class="bg-no-repeat bg-image-computers-machine"
        button_title="{{'more' | translate}}"
      ></list-cards>
    </div>

    <div
      class="
        flex flex-col-reverse
        lg:flex-col
        flex-nowrap
        lg:py-32
        items-center
      "
    >
      <div class="w-full flex flex-col lg:flex-row justify-center items-center">
        <img class="" width="600" src="..//assets/images/lap.png" alt="" />
        <div class="w-screen lg:w-10/12 py-10 px-5 lg:py-32 text-center">
          <span
            class="
              text-white
              w-full
              text-center
              px-2
              text-xl
              lg:text-4xl
            "
            style="font-family: Almarai-Regular"
          >
            {{'we_care_about_details_constantly_improve_and_innovate_solutions' | translate}}
          </span>
        </div>
      </div>
    </div>

    <div class="flex justify-center w-full lg:py-48 px-5 lg:px-0">
      <div class="flex flex-col w-full px-3 lg:px-0 lg:w-1200 lg:h-504 h-auto">
        <div class="flex lg:flex-1 flex-col lg:flex-row">
          <div
            data-aos="fade-right"
            data-aos-offset="0"
            data-aos-duration="700"
            class="
              flex flex-1
              justify-center
              items-center
              ml-0
              lg:ml-4
              mb-4
              rounded-lg
              from-theme-primary
              to-theme-secondary
              bg-gradient-to-r
              from-ColorMain4
              shadow-xl
            "
          >
            <span
              class="text-white text-2xl lg:text-5xl"
              style="
                font-family: Almarai-Regular;
                font-weight: normal;
                text-align: center;
                line-height: 50px;
                padding: 5px;
              "
            >
              {{'expand_your_projects_and_ensure_continuity_with_various_technologies' | translate}}
            </span>
          </div>
          <div
            data-aos="fade-left"
            data-aos-offset="0"
            data-aos-duration="2000"
            class="
              flex flex-1
              justify-center
              items-center
              mb-4
              ml-0
              lg:ml-4
              py-5
              lg:py-0
              rounded-lg
              bg-black
              bg-hero-pattern
              bg-contain bg-no-repeat
            "
          >
            <span
              class="text-white text-2xl lg:text-3xl text-center lg:text-right"
              style="
                font-family: Almarai-Regular;
                font-weight: normal;
                line-height: 63px;
              "
            >
              <span class="text-ColorMain1">{{'we' | translate}}</span> {{'design1' | translate}}
              <br />
              <span class="text-ColorMain1">{{'we' | translate}}</span> {{'innovate' | translate}}
              <br />
              <span class="text-ColorMain1">{{'we' | translate}}</span> {{'understand_to_be_with_you_step_by_step' | translate}}
            </span>
          </div>
        </div>
        <div
          class="
            flex flex-1
            rounded-lg
            ml-0
            lg:ml-4
            mb-4
            h-96
            bg-white bg-opacity-10 bg-blend-color
            shadow-inner
            border-white border-opacity-25
          "
          style="border-width: 0.2px"
        >
          <div class="flex flex-col lg:flex-row w-screen">
            <div class="flex justify-center flex-1 px-2 lg:px-0">
              <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
              <lottie-player
                src="https://assets5.lottiefiles.com/packages/lf20_tutyy3hj.json"
                background="transparent"
                speed="1"
                loop
                autoplay
              ></lottie-player>
            </div>
            <div
              class="
                p-5
                flex flex-col flex-1
                justify-center
                items-center
                text-white text-3xl
                lg:text-4xl
              "
            >
              <div style="font-family: Almarai-bold">{{'easy_solution_to_ensure' | translate }}</div>
              <div style="font-family: Almarai-Regular">{{'satisfaction_of_your_customers' | translate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        effect
        flex flex-col
        justify-center
        items-center
        w-screen
        mb-20
        h-screen
        left-0
        right-0
      "
      style="
        background: linear-gradient(
          179deg,
          rgba(35, 35, 98, 1) 0%,
          rgba(44, 59, 150, 1) 50%,
          rgba(0, 174, 194, 1) 99%
        );
        position: fixed;
        z-index: -3;
      "
    >
      <div class="h-screen"></div>
    </div>

    <!--part-->

    <div>
      <div
        class="
          text-white text-3xl
          py-10
          lg:text-4xl
          px-10
          lg:px-0
          mb-5
          lg:mb-36
          text-center
          hover:text-ColorMain1
        "
        style="font-family: Almarai-bold"
      >
        {{'our_methodology_used_to_reach_your_business_goals' | translate}}
      </div>

      <!--approach-->

      <div class="flex flex-col lg:flex-row justify-around lg:mb-60 mr-0">
        <div
          class="flex flex-col lg:flex-row justify-between text-center px-10 lg:px-20"
        >

          <div class="flex-1 lg:px-10 py-10">
            <div class="w-full flex-col flex items-center">
              <div class="bg-gray-200 rounded-full p-4">
                <img src="../assets/images/approach1.png" alt="" />
              </div>
              <h2
                class="text-white p-4 hover:text-ColorMain1"
                style="font-family: Almarai-bold"
              >
              {{'concept' | translate}}
              </h2>
              <span class="text-white" style="font-family: Almarai-Regular">
                {{'transform_your_idea_from_a_concept_to_a_minimum_product_MVP' | translate}}
              </span>
            </div>
          </div>

          <div class="flex-1 lg:px-10 py-10">
            <div class="w-full flex-col flex items-center">
              <div class="bg-gray-200 rounded-full p-4">
                <img src="../assets/images/approach2.png" alt="" />
              </div>
              <h1
                class="text-white p-4 hover:text-ColorMain1"
                style="font-family: Almarai-bold"
              >
              {{'design2' | translate}}
              </h1>
              <span class="text-white" style="font-family: Almarai-Regular">
                {{'drawing_and_designing_the_product_to_match_the_needs_of_the_user' | translate}}
              </span>
            </div>
          </div>

          <div class="flex-1 lg:px-10 py-10">
            <div class="w-full flex-col flex items-center">
              <div class="bg-gray-200 rounded-full p-4">
                <img src="../assets/images/approach3.png" alt="" />
              </div>
              <h1
                class="text-white p-4 hover:text-ColorMain1"
                style="font-family: Almarai-bold"
              >
                {{'development' | translate}}
              </h1>
              <span class="text-white" style="font-family: Almarai-Regular">
                {{'turn_your_design_into_a_real_product_and_software' | translate}}
              </span>
            </div>
          </div>

          <div class="flex-1 lg:px-10 py-10">
            <div class="w-full flex-col flex items-center">
              <div class="bg-gray-200 rounded-full p-4">
                <img src="../assets/images/approach4.png" alt="" />
              </div>
              <h1
                class="text-white p-4 hover:text-ColorMain1"
                style="font-family: Almarai-bold"
              >
                {{'publishing' | translate}}
              </h1>
              <span class="text-white" style="font-family: Almarai-Regular">
                {{'publishing_and_launching_the_product_to_market' | translate}}
              </span>
            </div>
          </div>

        </div>
      </div>

      <div
        class="
          flex flex-col
          lg:flex-row
          justify-around
          z-10
          bg-gradient-primary-to-black
          to-ColorMain3
          shadow
        "
      >
        <div
          class="
            flex flex-col flex-1
            justify-center
            z-10
            to-ColorMain3
            shadow
            rounded-lg
          "
        >
          <span
            class="text-white xl:text-4xl lg:text-4xl md:text-4xl text-2xl lg:px-10 py-20 text-center"
            style="font-family: Almarai-bold"
          >
            {{'carry_out_your_work_through_a_professional_team' | translate}}
            <br>
            {{'and_global_best_practices' | translate}}
          </span>
        </div>

        <div
          class="
            flex flex-col flex-1
            justify-center
            z-10
            to-ColorMain3
            shadow
            rounded-lg
            h-full
          "
        >
          <img src="..//../assets/images/work1.jpg" alt="" />
        </div>

        <div></div>
      </div>
    </div>
    <!---->

    <!-- <div
      class="
        flex flex-col-reverse
        lg:flex-row
        w-screen
        items-center
        py-32
        px-28
        lg:px-48
      "
    >
      <div class="w-full lg:w-screen">
        <img src="..//assets/images/work2.png" alt="" />
      </div>
      <div class="w-screen">
        <div
          class="text-white text-4xl mb-36 text-center"
          style="font-family: Almarai-bold"
        >
          صمم منتجاتك بعناية حتى تترك أثرا لعملائك
        </div>
      </div>
    </div> -->

    <!---->
    <!-- <div
      class="flex flex-col items-center py-10"
      style="position: relative; z-index: 1"
    >
      <div
        style="font-family: Almarai-bold"
        class="
          pr-11
          ml-20
          lg:ml-0 lg:pl-28
          text-center text-lg
          lg:text-xl
          text-white
        "
      >
        يسعدنا أن تتواصل معنا من خلال نموذج التواصل أدناه
      </div>
      <small></small>
      <img class="flex" width="300px" src="..//assets/images/done.svg" alt="" />
    </div> -->

    <contact typeService="MAIN"></contact>

    <footer class="bg-transparent"></footer>
  </div>
</div>
