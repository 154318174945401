import { Component, Input, OnInit } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'card-with-image',
  templateUrl: './card-with-image.component.html',
  styleUrls: ['./card-with-image.component.css'],
})
export class CardWithImageComponent implements OnInit {
  @Input() title = '';
  @Input() icon: IconProp = 'car';
  @Input() desc = '';
  @Input() image = '';
  @Input() class = '';
  @Input() classContent = 'bg-gradient-primary-to-black';

  constructor() {}

  ngOnInit(): void {}
  contactDown() {
    window.location.href = `development/#contact`;
    console.log('click');
  }
}
