<div class="bg-transparent">
  <!-- <div
    class="top-0 right-0 left-0 bottom-0 fixed"
    style="
      background: linear-gradient(
        179deg,
        rgba(35, 35, 98, 1) 0%,
        rgba(44, 59, 150, 1) 50%,
        rgba(0, 174, 194, 1) 99%
      );
      z-index: -1;
    "
  ></div> -->
  <router-outlet></router-outlet>
  <!--
<div class="section" id="home"></div>
<div class="section" id="about"></div>
<div class="section" id="features"></div>
<div class="section" id="contact"></div> -->
</div>
