import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import data from '../../../data/market';
@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.css'],
})
export class MarketingComponent implements OnInit {
  card_data:any;
  constructor(
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {

    this.translate.get('data_marketing').subscribe((data:any)=> {
      this.card_data = [
        {
          title: data.advertising_campaigns,
          desc: data.advertising_campaigns_desc,
          url: 'social-media',
          link: 'marketing/#contact',
        },
        {
          title: data.marketing,
          desc: data.marketing_desc,
          url: 'digital-marketing',
          link: 'marketing/#contact',
        },
        {
          title: data.social_media_management,
          desc: data.social_media_management_desc,
          url: 'loudspeaker',
          link: 'marketing/#contact',
        },
        {
          title: data.promotional_videos,
          desc: data.promotional_videos_desc,
          url: 'ads',
          link: 'marketing/#contact',
        },
        {
          title: data.ad_design,
          desc: data.ad_design_desc,
          url: 'video',
          link: 'marketing/#contact',
        },
        {
          title: data.seo,
          desc: data.seo_desc,
          url: 'seo',
          link: 'marketing/#contact',
        }
      ]
    }
  )};

  upfun() {
    window.scrollTo(0, 0);
  }

  downfun() {
    window.scrollTo(100, 950);
  }
}
