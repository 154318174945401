<div
  class="
    h-screen
    w-screen
    flex
    lg:flex-row
    flex-col
    justify-center
    items-center
  "
  style="
    background: rgb(100, 17, 104);
    background: linear-gradient(
      180deg,
      rgba(100, 17, 104, 1) 19%,
      rgba(216, 38, 224, 1) 100%
    );
  "
>
  <div
    style="font-family: Almarai-Regular"
    class="text-ColorMain1 w-4/12 lg:text-sm text-center text-sm"
  >
    <h1
      style="font-family: Almarai-Regular"
      class="text-white lg:text-3xl text-center text-xl"
    >
      !! عذرا<br />
      نأسف لهذا الخطأ ربما قمت بكتابة رابط غير موجود أو غير متاح في الوقت الحالي
    </h1>
    <div>
      <a class="text-sm" href="./"> العودة للموقع من هنا </a>
    </div>
  </div>
  <div class="">
    <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
    <lottie-player
      src="https://assets1.lottiefiles.com/packages/lf20_58bmsu1o.json"
      background="transparent"
      speed="1"
      loop
      autoplay
    ></lottie-player>
  </div>
</div>
