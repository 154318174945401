<div
  id="feature-box"
  class="
    flex-1
    h-96
    w-72
    shadow-xl
    rounded-lg
    flex flex-col
    justify-center
    items-center
    overflow-hidden
    relative
    bg-gradient-to-tr
    from-ColorMain4
    to-ColorMain5
    mb-5
    lg:mb-0
  "
  style="z-index: -1px"
>
  <div
    id="circle-feature"
    class="
      w-72
      h-72
      rounded-full
      absolute
      
      shadow-xl
      -top-16
      transition-all
      duration-200
      {{ class }}
    "
  ></div>
  <div class="h-32 w-32 flex justify-center p-5 items-center mb-10 z-10">
    <img
      src="../../../../assets/images/{{ src }}.svg"
      class="w-full h-1/w-full"
      alt=""
    />
  </div>
  <span
    class="
      font-Almarai-bold
      text-xl text-white text-center
      px-5
      z-10
      transition-all
      duration-200
    "
    >{{ title }}</span
  >
</div>
<!-- bg-gradient-to-tr
    from-ColorMain4
    to-ColorMain5 -->
