<div
  class="
    bg-white bg-opacity-50
    hover:bg-opacity-70
    transition-all
    duration-200
    backdrop-filter
    bg-clip-padding
    rounded-xl
    shadow-lg
    bg-blere
    border-2 border-gray-400
    z-50
    py-5
    px-10
    lg:px-28
    text-center
    h-full
    flex flex-col flex-shrink-0
    items-center
    justify-between
    text-theme-primary
  "
  style="backdrop-filter: blur(20px)"
>

  <div class="w-full flex h-24 justify-center">
    <img class="w-12" src="../../assets/images/{{ url }}.svg" alt="" />
  </div>
  <div class="text-3xl font-Almarai-bold my-5">{{'services' | translate}} <br />{{ title }}</div>
  <p class="text-lg font-Almarai-bold">
    {{ desc }}
  </p>
  <div
    (click)="goto(link)"
    class="
      w-11/12
      py-5
      rounded-xl
      shadow
      bg-opacity-90 bg-theme-primary
      hover:bg-theme-secondary
      text-white
      cursor-pointer
      font-Almarai-Regular
    "
  >
    {{ button_title }}
  </div>
</div>
