import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../user';

import { ContactService } from '../../contact.service';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';

@Component({
  selector: 'app-development-service',
  templateUrl: './development-service.component.html',
  styleUrls: ['./development-service.component.css'],
})
export class DevelopmentServiceComponent implements OnInit {
  submitting = false;
  submitted = false;

  public mybutton = document.getElementById('myBtn');

  private submissionForm: AngularFirestoreCollection<any> | null = null;

  userModel = new User('', '', null, '', '', '');

  @Input() link: string = '#contact';

  constructor(
    private _contactService: ContactService,
    private firestore: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.submissionForm = this.firestore.collection('submissions');
  }

  onSubmit() {
    this.submitted = true;
    this._contactService.contact(this.userModel).subscribe(
      (data) => console.log('sucess!', data),
      (error) => console.error('error!', error)
    );
  }

  upfun() {
    window.scrollTo(0, 0);
  }

  downfun() {
    window.scrollTo(100, 950);
  }

  downfun2() {
    window.location.href = '/development/#web-section';
  }

  contactDown() {
    window.scrollTo(100, 6500);
  }

  submitData(value: any) {
    // fireBase part
    this.submitted = true;
    this.submissionForm
      ?.add(value)
      .then((res) => {
        this.submitted = true;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.submitting = false;
      });
  }

  goto() {
    window.location.href = `development/#contact`;
    console.log('click');
  }
}
