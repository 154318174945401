import { Component, Input, OnInit } from '@angular/core';
import * as AOS from 'aos';
@Component({
  selector: 'card-section',
  templateUrl: './card-section.component.html',
  styleUrls: ['./card-section.component.css'],
})
export class CardSectionComponent implements OnInit {
  @Input() title = '';
  @Input() desc = '';
  @Input() delay = 900;
  @Input() link = '#contact';
  @Input() url = '';

  @Input() button_title = 'عرض السعر';
  constructor() {}

  ngOnInit(): void {
    AOS.init();
  }
  goto(url: string) {
    window.location.href = url;
    console.log('click');
  }
}
