import { Component, OnInit, HostBinding } from '@angular/core';
import { User } from './user';
import * as AOS from 'aos';
import { ContactService } from './contact.service';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { ScrollService } from './scroll.service';

import { AngularFireAnalytics } from '@angular/fire/analytics';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    // animation triggers go here

    trigger('flyInout', [
      transition('void => *', [
        style({ transform: 'translatey(-300%)' }),
        animate('1s'),
      ]),
    ]),

    trigger('shows', [
      transition('void => *', [
        style({ transform: 'translatex(-300%)' }),
        animate('3s'),
      ]),
    ]),

    trigger('showsmenu', [
      transition('void => *', [style({ width: '100vw' }), animate('3s')]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  title = 'Creative Clouds IT';
  showDiv = true;
  showMenu = false;
  topics = [
    'تصميم وتطوير البرمجيات',
    'التسويق',
    'التحليل',
    'تجربة المستخدم',
    'إدارة وتشغيل التطبيقات',
  ];
  topicHasError = true;
  submitting = false;
  submitted = false;
  logoWidth = 0;
  faCoffee = faCoffee;

  public lottieConfig: Object;
  private anim: any;
  public animationSpeed: number = 1;

  public mybutton = document.getElementById('myBtn');

  private submissionForm: AngularFirestoreCollection<any> | null = null;

  userModel = new User('', '', null, '', '', '');

  constructor(
    public translate: TranslateService,
    private _contactService: ContactService,
    private firestore: AngularFirestore,
    private scrollService: ScrollService,
    analytics: AngularFireAnalytics
  ) {
    analytics.logEvent('custom_event', {
      content_type: 'image',
      content_id: 'P12453',
      items: [{ name: 'Kittens' }],
    });

    this.lottieConfig = {
      path: 'assets/Files/ping.json',
      autoplay: true,
      loop: true,
    };

    scrollService.currentSection.subscribe((res) => {
      console.log('current section: ', res);
    });
  }

  closeSideMenu() {
    if (this.showMenu) {
      this.logoWidth = 0;
      this.showMenu = false;
    } else {
      this.logoWidth = 100;
      this.showMenu = true;
    }
  }

  ngOnInit(): void {
    AOS.init();
    this.submissionForm = this.firestore.collection('submissions');

    const body = document.getElementsByTagName('body');

    // check if currentLang isset in local storage
    if (localStorage.getItem('currentLang')) {
      this.translate.use(localStorage.getItem('currentLang') || 'en');

    } else {
      localStorage.setItem('currentLang', 'en');
      this.translate.use('en');

    }

    if (localStorage.getItem('currentLang') == 'ar') {
      body[0].setAttribute('dir', 'rtl');
    } else {
      body[0].setAttribute('dir', 'ltr');
    }

  }

  validateTopic(value: string) {
    if (value === 'default') {
      this.topicHasError = true;
    } else {
      this.topicHasError = false;
    }
  }

  onSubmit() {
    this.submitted = true;
    this._contactService.contact(this.userModel).subscribe(
      (data) => console.log('sucess!', data),
      (error) => console.error('error!', error)
    );
  }

  upfun() {
    window.scrollTo(0, 0);
  }

  submitData(value: any) {
    // fireBase part
    this.submitted = true;
    this.submissionForm
      ?.add(value)
      .then((res) => {
        this.submitted = true;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.submitting = false;
      });
  }

  goto(url: string) {
    window.location.href = url;
    console.log('click');
  }

  handleAnimation(anim: any) {
    console.log('before create ');

    this.anim = anim;

    console.log('after create ');
  }

  stop() {
    this.anim.stop();
  }

  play() {
    this.anim.play();
  }

  pause() {
    this.anim.pause();
  }
  setSpeed(speed: string) {
    const value = parseInt(speed);
    this.animationSpeed = value;
    this.anim.setSpeed(value);
  }
}
