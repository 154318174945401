export class User {

    constructor(
        public name: string,
        public email: string,
        public phone: number|null,
        public topic: string,
        public weburl: string,
        public message: string,
        


    ){}
}
