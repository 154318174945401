<div
  class="
    w-screen
    h-auto
    relative
    overflow-hidden
    bg-cover lg:bg-fixed
    bg-no-repeat bg-gradient-to-bl
    {{ class }}
  "
>
  <!-- <div
    class="w-full h-full absolute z-10 p-5 bg-black"
    style="z-index: -1"
  ></div> -->
  <div class="w-full py-10 text-4xl font-Almarai-bold text-white text-center">
    {{ 'our_services' | translate }}
  </div>
  <div
    class="
      w-full
      grid-cols-1 grid
      lg:grid-cols-3
      z-10
      gap-5
      relative
      px-10
      pb-10
    "
  >
    <ng-container *ngFor="let item of data">
      <card-section
        url="{{ item.url }}"
        title="{{ item.title }}"
        desc="{{ item.desc }}"
        button_title="{{ button_title }}"
        link="{{ item.link }}"
      ></card-section>
    </ng-container>
  </div>
</div>
