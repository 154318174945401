import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'navbar-pc',
  templateUrl: './navbar-pc.component.html',
  styleUrls: ['./navbar-pc.component.css'],
})
export class NavbarPcComponent implements OnInit {
  @Input() class = '';
  currentLang = localStorage.getItem('currentLang') || 'en';
  constructor(
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    window.onscroll = function (e: any) {
      let navBar = document.querySelector('#navbar');
      let upButton = document.querySelector('#upBtn');

      if (!navBar) return;
      window.addEventListener('scroll', (event) => {
        let scroll = this.scrollY | 0;
        // console.log(scroll);
        if (scroll > 200) {
          if (!navBar?.classList.contains('active-scroll'))
            navBar?.classList.add('active-scroll');

            upButton?.classList.remove('invisible');
            upButton?.classList.add('visible');

        } else {
          if (navBar?.classList.contains('active-scroll'))
            navBar?.classList.remove('active-scroll');

            upButton?.classList.remove('visible');
            upButton?.classList.add('invisible');
        }
      });
    };
  }

  changeCurrentLang(lang: string) {
    // window.location.reload();
    const body = document.getElementsByTagName('body');

    if (lang == 'ar') {
      body[0].setAttribute('dir', 'rtl');
      this.translate.use(lang);
      localStorage.setItem('currentLang', 'ar');

      window.location.reload();

    } else {
      this.translate.use(lang);
      body[0].setAttribute('dir', 'ltr');
      localStorage.setItem('currentLang', 'en');

      window.location.reload();

    }
  }

}
