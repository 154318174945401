import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'feature-box',
  templateUrl: './feature-box.component.html',
  styleUrls: ['./feature-box.component.css'],
})
export class FeatureBoxComponent implements OnInit {
  @Input() title = '';
  @Input() src = '';
  @Input() outline = false;
  @Input() class = 'bg-ColorMain3';
  constructor() {}

  ngOnInit(): void {}
}
