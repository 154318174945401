<div
  id="navbar"
  class="flex fixed w-screen top-0 z-20 h-20"
  style="z-index: 100"
>
  <div
    class="
      flex
      justify-between
      items-center
      relative
      flex-wrap
      w-screen
      h-full
    "
  >

  <div
  class="
    items-center
    justify-end
    px-16
    h-full
    hidden
    lg:flex
  "
>
  <div class="text-white text-xl font-Almarai-bold flex items-center">
    <img class="w-14 mr-6" src="..//assets/images/logo.png" alt="" />
    {{ 'company_name' | translate }}
  </div>
</div>
    <!-- <div
      id="showHeaderColor"
      class="top-0 left-0 bottom-0 transition-all duration-200  {{
        class
      }}"
    ></div> -->
    <div
      class="
        justify-between
        items-center
        w-5/12
        text-white text-xl
        pt-4
        hidden
        lg:flex
        px-10
      "
      style="font-family: Almarai-Regular"
    >


      <a
        class="hover:opacity-100 opacity-70 transition-opacity duration-150 p-4"
        href="#home"
        >
        {{ 'home' | translate }}
        </a
      >

      <div class="dropdown">
        <button class="dropbtn hover:opacity-100 opacity-70">
          {{ 'services' | translate }}
          <fa-icon class="mr-4" [icon]="['fas', 'caret-down']"></fa-icon>
        </button>
        <div class="dropdown-content">
          <a href="development">{{ 'software_development' | translate }}</a>
          <a href="design">{{ 'graphic_design' | translate }}</a>
          <a href="marketing">{{ 'digital_marketing' | translate }}</a>
        </div>
      </div>

      <a class="hover:opacity-100 opacity-70 transition-opacity duration-150 p-4" href="#about">{{ 'about_us' | translate }}</a>
      <a class="hover:opacity-100 opacity-70 transition-opacity duration-150 p-4" href="#contact">{{ 'contact' | translate }}</a>

      <a *ngIf="currentLang == 'ar'" (click)="changeCurrentLang('en')" class="cursor">{{ 'en' | translate }}</a>
      <a *ngIf="currentLang == 'en'" (click)="changeCurrentLang('ar')" class="cursor">{{ 'ar' | translate }}</a>

    </div>
  </div>
</div>
