import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  @Input() class = '';
  showDiv = true;
  showMenu = false;
  logoWidth = 0;
  heightService: any = '0px';
  rotatedValue = 'rotate(0deg)';
  flagEn: boolean = false;
  flagAr: boolean = false;
  constructor(
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    // set one of the flag if the current language is english or arabic
    if (localStorage.getItem('currentLang') == 'ar') {
      this.flagEn = true;
      this.rotatedValue = 'rotate(180deg)';
    } else {
      this.flagAr = true;

    }
  }
  closeSideMenu() {

    let menu = document.querySelector('#nav-icon1');
    console.log(menu);
    menu?.classList.toggle('open');
    console.log('after : ', menu);
    if (this.showMenu) {
      this.logoWidth = 0;
      this.showMenu = false;
    } else {
      this.logoWidth = 100;
      this.showMenu = true;
    }
  }
  onOpenServices() {
    // console.log('open');
    // console.log('before ', this.rotatedValue);
    if (this.heightService === '0px') {
      this.heightService = '150px';
      this.rotatedValue = 'rotate(90deg)';
    } else {
      this.heightService = '0px';
      this.rotatedValue = 'rotate(0deg)';
    }
    // console.log('after ', this.rotatedValue);
  }
  openWhatsapp() {
    window.location.href = 'https://api.whatsapp.com/send?phone=+966598080208';
  }

  changeCurrentLangMobile(lang: string) {
    // window.location.reload();
    const body = document.getElementsByTagName('body');

    if (lang == 'ar') {
      body[0].setAttribute('dir', 'rtl');
      this.translate.use(lang);
      localStorage.setItem('currentLang', 'ar');

      window.location.reload();

    } else {
      this.translate.use(lang);
      body[0].setAttribute('dir', 'ltr');
      localStorage.setItem('currentLang', 'en');

      window.location.reload();

    }
  }

}
