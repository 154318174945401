import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../user';

import { ContactService } from '../../../contact.service';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';

type TYPE_SERVICES = 'DEVELOPMENT' | 'DESIGN' | 'MARKETING' | 'MAIN';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  submitting = false;
  submitted = false;
  @Input() typeService: TYPE_SERVICES = 'DEVELOPMENT';
  @Input() id: string = '';

  SelectionOption: string[] = [];

  public mybutton = document.getElementById('myBtn');

  private submissionForm: AngularFirestoreCollection<any> | null = null;

  userModel = new User('', '', null, '', '', '');

  constructor(
    private _contactService: ContactService,
    private firestore: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.submissionForm = this.firestore.collection('submissions');
    console.log(this.typeService);
    if (this.typeService === 'DEVELOPMENT') {
      this.SelectionOption = [
        'تطوير تطبيقات الويب',
        'تطوير تطبيقات الموبايل',
        'تطوير تطبيقات سطح المكتب',

        'تصميم واجهة وتجربة المستخدم',
        'تصميم وبرمجة المتاجر',
        '  إدارة وتشغيل التطبيقات',
      ];
    } else if (this.typeService === 'DESIGN') {
      this.SelectionOption = [
        'التصميم الجرافيكي',
        'موشن جرافيك',
        'تصميم واجهة وتجربة المستخدم',
        'التصاميم ثلاثية الأبعاد',
        'مونتاج الفيديو والتعديل  ',
        '  تصميم الهوية البصرية والشعارات  ',
      ];
    } else if (this.typeService === 'MARKETING') {
      this.SelectionOption = [
        'إدارة مواقع التواصل الاجتماعي',
        'إنشاء حملات إعلانية',
        'تصميم الإعلانات',
        'الفديوهات الترويجية',
        'SEO',
      ];
    } else if (this.typeService === 'MAIN') {
      this.SelectionOption = [
        'تطوير البرمجيات',
        'تصميم الجرافيكي',
        'التسويق الالكتروني',
      ];
    }
  }

  onSubmit() {
    this.submitted = true;
    this._contactService.contact(this.userModel).subscribe(
      (data) => console.log('sucess!', data),
      (error) => console.error('error!', error)
    );
  }

  submitData(value: any) {
    // fireBase part
    this.submitted = true;
    this.submissionForm
      ?.add(value)
      .then((res) => {
        this.submitted = true;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.submitting = false;
      });
  }
}
