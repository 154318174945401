import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  // @Input () class = '';

  constructor() {}

  ngOnInit(): void {}

  upfun() {
    window.scrollTo(0, 0);
  }

  goto(url: string) {
    window.location.href = 'google';

    console.log('click');
  }

  goto2(url: string, target: string = '_blank') {
    window.open(url, target);
  }
}
