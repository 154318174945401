<div class="">
  <!-- <div>
    <div class="z-0">
      <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
      <lottie-player
        src="https://assets8.lottiefiles.com/packages/lf20_nevctvni.json"
        background="transparent"
        speed="1"
        style="width: 100%; height: 600px"
        loop
        autoplay
      ></lottie-player>
    </div>
  </div> -->

  <navbar class="lg:bg-theme-third"></navbar>

  <div
    class="
      flex flex-col
      lg:flex-row
      justify-center
      items-center
      lg:w-screen
      h-full
      fixed
      top-0
      z-0
      right-0
      left-0
      bottom-0
      overflow-hidden
    "
    style="
      background: rgb(193, 112, 153);
      background: linear-gradient(
        to bottom left,
        rgb(100, 17, 104) 0%,
        rgb(183, 64, 189) 50%,
        rgb(221, 113, 227) 99%
      );
    "
  >
    <!-- <div class="h-full" style="z-index: -2">
      <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
      <lottie-player
        src="https://assets3.lottiefiles.com/private_files/lf30_ffmh1in2.json"
        background="transparent"
        speed="1"
        style="
          width: 100vh;
          height: 90vh;

          transform: scale(2);
        "
        loop
        autoplay
      ></lottie-player>
    </div> -->
  </div>
  <hero-section
    title="{{'software_development_services' | translate}}"
    desc="{{'software_development_services_hero_description' | translate}}"
  >
    <lottie-player
      id="hero-section-animation"
      src="https://assets4.lottiefiles.com/packages/lf20_lln7m43m.json"
      background="transparent"
      style="width: 100%; height: 100%"
      speed="1"
      loop
      autoplay
    ></lottie-player>
  </hero-section>

  <!-- <div class="flex flex-col lg:h-full bg-theme-third justify-around w-screen">
    <div class="flex justify-center bg-white"></div>
    <div class="flex flex-col justify-evenly mt-20 lg:flex-row px-20 pb-32">
      <div
        class="
          flex flex-col
          justify-center
          z-10
          mt-10
          bg-gradient-to-r
          from-ColorMain2
          to-ColorMain3
          shadow
          rounded-lg
        "
      >
        <span class="flex justify-center items-center">
          <fa-icon
            class="
              text-white
              hover:text-ColorMain1
              text-center text-8xl
              cursor-pointer
            "
            [icon]="['fab', 'buffer']"
          ></fa-icon
        ></span>
        <p
          class="text-xl lg:text-1xl text-center p-5 text-white z-10"
          style="font-family: Almarai-bold"
        >
          أبرز مانقدم في هذا المجال:
        </p>
        <br />
        <p
          class="text-xl lg:text-1xl px-4 text-center text-white z-10"
          style="font-family: Almarai-regular"
        >
          تطوير تطبيقات الويب<br />
          تطوير تطبيقات الموبايل
          <br />
          تطوير تطبيقات سطح المكتب
          <br />
          تطوير وتصميم واجهات وتجربة المستخدم
          <br />
          تطوير وتصميم المتاجر الإلكترونية
          <br />
          إدارة وتشغيل التطبيقات
        </p>
      </div>
      <div class="flex justify-center z-10"></div>

      <div
        class="
          flex flex-col
          justify-center
          z-10
          bg-gradient-to-r
          from-ColorMain2
          to-ColorMain3
          shadow
          rounded-lg
          mt-10
        "
      >
        <span class="flex justify-center items-center">
          <fa-icon
            class="
              text-white
              hover:text-ColorMain1
              text-center text-8xl
              cursor-pointer
            "
            [icon]="['fab', 'buffer']"
          ></fa-icon
        ></span>
        <p
          class="text-xl lg:text-1xl text-center p-5 text-white z-10"
          style="font-family: Almarai-bold"
        >
          ماذا تستفيد ؟
        </p>
        <br />
        <p
          class="text-xl lg:text-1xl text-center text-white px-4 space-y-2 z-10"
          style="font-family: Almarai-regular"
        >
          منتج قابل للتوسع متى ماكانت الحاجة لذلك<br />
          بنية تحتية تقنية عالية الجودة <br />
          سهولة الصيانة والتتبع لأخطاء النظام والتطوير <br />
        </p>
      </div>

      <div
        class="
          flex flex-col
          justify-center
          mt-10
          px-4
          z-10
          shadow
          rounded-lg
          mr-2
          bg-gradient-to-r
          from-ColorMain2
          to-ColorMain3
        "
      >
        <span class="flex justify-center items-center">
          <fa-icon
            class="
              text-white
              hover:text-ColorMain1
              text-center text-8xl
              cursor-pointer
            "
            [icon]="['fab', 'buffer']"
          ></fa-icon
        ></span>
        <p
          class="text-xl lg:text-1xl text-center p-5 text-white z-10"
          style="font-family: Almarai-bold"
        >
          المميزات
        </p>
        <br />
        <p
          class="text-xl lg:text-1xl text-center text-white z-10"
          style="font-family: Almarai-regular"
        >
          سرعة التحميل والاستخدام<br />
          نظام توثيق للمستخدمين عالي الأمان <br />
          لوحة تحكم مرنة وسلسة لتطبيقك <br />
        </p>
      </div>
    </div>
  </div> -->
  <!--serveces and price button-->
  <!-- [icon]="['fas', 'file-code']" -->
  <card-with-image
    id="#web-section"
    title="{{'web_application_development' | translate}}"
    desc="{{'web_application_development_description' | translate}}"
    image="web.jpg"
    [icon]="['fas', 'file-code']"
  ></card-with-image>
  <!-- [icon]="['fas', 'mobile-alt']" -->
  <card-with-image
    title="{{'mobile_application_development' | translate}}"
    desc="{{'mobile_application_development_description' | translate}}"
    image="mobile.jpg"
    [icon]="['fas', 'mobile-alt']"
    class="mb-10"
    classContent="bg-gradient-to-r  from-purple-400 to-red-500 via-pink-500"
  ></card-with-image>
  <!-- [icon]="['fas', 'laptop-code']" -->
  <card-with-image
    title="{{'desktop_application_development' | translate}}"
    desc="{{'desktop_application_development_description' | translate}}"
    image="pc.jpg"
    [icon]="['fas', 'laptop-code']"
  ></card-with-image>
  <!-- [icon]="['fas', 'pen-nib']" -->
  <card-with-image
    title="{{'design_and_development_of_interfaces_and_user_experience' | translate}}"
    desc="{{'design_and_development_description' | translate}}"
    image="ui-ux.jpg"
    [icon]="['fas', 'pen-nib']"
    classContent="bg-gradient-to-r  from-purple-400 to-red-500 via-pink-500"
  ></card-with-image>
  <!--E-commerce-->

  <card-with-image
    title="{{'development_and_design_of_electronic_stores' | translate}}"
    desc="{{'development_and_design_electronic_description' | translate}}"
    image="e-commerce.jpg"
    [icon]="['fas', 'funnel-dollar']"
    class="mt-10"
  ></card-with-image>

  <card-with-image
    class="mb-32"
    title="{{'management_and_operation' | translate}}"
    desc="{{'management_and_operation_description' | translate}}"
    image="manage.jpg"
    direction="flex-row-reverse"
    [icon]="['fas', 'user-cog']"
    classContent="bg-gradient-to-r  from-purple-400 to-red-500 via-pink-500 "
  ></card-with-image>
  <!-- <p>ماذا تستفيد</p> -->
  <div
    class="w-screen flex h-auto flex-col lg:h-96 justify-center items-center"
  >
    <p
      class="
        text-2xl
        lg:text-3xl
        font-Almarai-bold
        text-center text-white
        px-4
        py-20
        z-50
      "
    >
      {{ 'what_do_you_benefit_form' | translate }}
    </p>
    <div
      class="
        mt-20
        w-full
        lg:w-1/2
        h-auto
        lg:h-96
        flex
        items-center
        justify-around
        flex-col
        lg:flex-row
      "
    >
      <feature-box
        class="bg-gradient-primary-to-black"
        src="target-audience"
        title="{{'reaching_larger_segments_of_the_audience'| translate}}"
      ></feature-box>
      <feature-box
        class="bg-gradient-primary-to-black"
        src="quality"
        title="{{'high_quality_and_mastering_design_for_your_printed_and_digital_products'| translate}}"
      ></feature-box>
      <feature-box
        class="bg-gradient-primary-to-black"
        src="interactive"
        title="{{'attract_your_customers_with_innovative_marketing_campaigns'| translate}}"
      ></feature-box>
    </div>
  </div>
  <!-- <div
    class="bg-image-texture-ui w-full flex justify-center items-start h-504"
  ></div> -->
  <div class="flex flex-col h-full justify-around w-screen">
    <contact></contact>
    <!-- <div
      class="bg-image-texture-ui w-full flex justify-center items-start h-96"
    ></div> -->
    <footer class="bg-ColorMain5"></footer>
  </div>
</div>
